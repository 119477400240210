import { useToast } from "primevue/usetoast";
import { useUserStore } from "@/stores/user.store.js";
import RedirectView from "../pages/RedirectView.vue";

const routes = [
    {
        path: "/",
        redirect: '/home' 
    },
    { 
        path: "/login", 
        alias: ["/signin", "/sign-in"],
        name: "Login",
        component: RedirectView,
        beforeEnter: () => {
            window.location.href = `${ import.meta.env.VITE_API_URL }/auth/login`;
        },
    },
    { 
        path: "/register", 
        alias: ["/signup", "/sign-up"],
        name: "Register",
        component: RedirectView,
        beforeEnter: () => {
            window.location.href = `${ import.meta.env.VITE_API_URL }/auth/register`;
        },
    },
    { 
        path: '/signup-completion', 
        name: "SignupCompletion",
        component: () => import("@/pages/SignupCompletion/SignupCompletion.vue"),
        meta: {
            private: false,
        },
    },
    { 
        path: '/waiting-list', 
        name: "WaitingList",
        component: () => import("@/pages/SignupCompletion/WaitingList.vue"),
        meta: {
            private: false,
        },
    },
    { 
        path: '/profile', 
        name: "Profile",
        meta: {
            private: true,
        },
        component: () => import("@/pages/ProfileView.vue"),
    },
    { 
        path: '/home', 
        name: "Home",
        component: () => import("@/pages/LandingView.vue"),
        meta: {
            private: true,
        }
    },
    { 
        path: '/suppliers', 
        component: () => import("@/pages/Suppliers/SupplierListView.vue"),
        meta: {
            private: true,
        },
    },
    { 
        path: '/attachments', 
        component: () => import("@/pages/Attachments/AttachmentListView.vue"),
        meta: {
            private: true,
        },
    },
    { 
        path: '/importers', 
        children: [
            { 
                path: '', 
                component: () => import("@/pages/Importers/ImporterListView.vue"),
                meta: {
    
                    private: true,
                },
            },
            { 
                path: 'new', 
                component: () => import("@/pages/Importers/ImporterEditView.vue"),
                meta: {
                    private: true,
                },
            },
        ],
        meta: {
            private: true,
        },
    },
    { 
        path: '/imports', 
        children: [
            { 
                path: '', 
                component: () => import("@/pages/Imports/ImportListView.vue"),
                meta: {
    
                    private: true,
                },
            },
            { 
                path: ':cuid', 
                component: () => import("@/pages/Imports/ImportEditView.vue"),
                meta: {
                    private: true,
                },
            },
        ],
    },
    { 
        path: '/installations', 
        children: [
            { 
                path: '', 
                name: "InstallationList",
                component: () => import("@/pages/Installations/InstallationListView.vue"),
                meta: {
    
                    private: true,
                },
            },
            { 
                path: ':cuid', 
                name: "InstallationEdit",
                component: () => import("@/pages/Installations/InstallationEditView.vue"),
                meta: {
                    private: true,
                },
            },
        ],
    },
    { 
        path: '/emissions', 
        children: [
            { 
                path: '', 
                name: "EmissionList",
                component: () => import("@/pages/Emissions/EmissionListView.vue"),
                meta: {
    
                    private: true,
                },
            },
            { 
                path: ':cuid', 
                name: "EmissionEdit",
                component: () => import("@/pages/Emissions/EmissionEditView.vue"),
                meta: {
                    private: true,
                },
            },
        ],
    },
    {
        path: "/onboarding",
        name: "Onboarding",
        component: () => import("@/pages/Onboarding/OnboardingView.vue"),
        meta: {
            private: true,
        },
    },
    { 
        path: '/production-processes', 
        children: [
            { 
                path: '', 
                name: "ProductionProcessList",
                component: () => import("@/pages/ProductionProcesses/ProductionProcessListView.vue"),
                meta: {
    
                    private: true,
                },
            },
            { 
                path: ':cuid', 
                name: "ProductionProcessEdit",
                component: () => import("@/pages/ProductionProcesses/ProductionProcessEditView.vue"),
                meta: {
    
                    private: true,
                },
            },
        ],
    },
    { 
        path: '/data-requests', 
        children: [
            { 
                path: '', 
                name: "DataRequestList",
                component: () => import("@/pages/DataRequests/DataRequestListView.vue"),
                meta: {
    
                    private: true,
                },
            },
            { 
                path: ':cuid', 
                name: "DataRequestEdit",
                component: () => import("@/pages/DataRequests/DataRequestEditView.vue"),
                meta: {
    
                    private: true,
                },
            },
        ]
    },
    { 
        path: '/process-records', 
        children: [
            { 
                path: '', 
                name: "ProcessRecordList",
                component: () => import("@/pages/ProcessRecords/ProcessRecordListView.vue"),
                meta: {
                    private: true,
                },
            },
            { 
                path: ':cuid', 
                name: "ProcessRecordEdit",
                component: () => import("@/pages/ProcessRecords/ProcessRecordEditView.vue"),
                meta: {
                    private: true,
                },
            },
        ]
    },
    { 
        path: '/reports', 
        children: [
            { 
                path: '', 
                name: "ReportList",
                component: () => import("@/pages/Reports/ReportListView.vue"),
                meta: {
                    private: true,
                },
            },
            { 
                path: ':cuid', 
                name: "ReportEdit",
                component: () => import("@/pages/Reports/ReportEditView.vue"),
                meta: {
                    private: true,
                },
            },
        ],
    },
    { 
        path: '/settings', 
        component: () => import("@/pages/Settings/SettingsView.vue"),
        children: [
            {
                path: "",
                redirect: "/settings/details"
            },
            { 
                path: 'details', 
                component: () => import("@/pages/Settings/ManageCompanyDetails.vue"),
                meta: {
    
                    private: true,
                },
            },
            { 
                path: 'team', 
                component: () => import("@/pages/Settings/ManageTeamView.vue"),
                meta: {
    
                    private: true,
                },
            },
            { 
                path: 'billing', 
                component: () => import("@/pages/Settings/ManageBillingView.vue"),
                meta: {
    
                    private: true,
                },
            },
        ],
        meta: {
            private: true,
        },
    },
    // Maintenance page
    {
        path: "/maintenance",
        name: "Maintenance",
        component: () => import("@/pages/MaintenanceView.vue"),
    },
    // 404 page
    {
        path: "/:pathMatch(.*)*",
        component: () => import("@/pages/NotFoundView.vue"),
    },
];

export default routes;